<template>
<div>
  <div v-for="(item, k) in value">
    <component :is="components[getComponentType(item)]" :param="k" :value="item" />
  </div>
</div>
</template>

<script>
import actionString from "@/components/logs/actionString.vue";
import actionArray from "@/components/logs/actionArray.vue";
// import actionChange from '@/components/logs/actionСhange.vue'
import actionBoolean from "@/components/logs/actionBoolean.vue";
export default {
  name: "actionObject",
  props: ['value', 'param'],
  data() {
    return {
      components: {
        string: actionString,
        // object: actionChange,
        array: actionArray,
        boolean: actionBoolean,
        number: actionString
      }
    }
  },
  methods: {
    getComponentType(item) {
      if(Array.isArray(item)) {
        return 'array';
      }
      if(typeof item === 'string') {
        return 'string';
      }
      if (typeof item === 'boolean') {
        return 'boolean';
      }
      if (typeof item === 'number') {
        return 'number'
      }
      return 'object';
    }
  },
}
</script>

<style scoped>

</style>