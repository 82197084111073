<template>
  <div>
    <div v-for="keyItem in keysRange">
      <component v-if="value[keyItem] && !Array.isArray(value[keyItem]) 
          || (value[keyItem] && Array.isArray(value[keyItem]) && value[keyItem].length)" 
      :is="components[getComponentType(value[keyItem])]" :param="keyItem" :value="value[keyItem]" />
    </div>
  </div>
</template>

<script>
import actionObject from "@/components/logs/actionObject.vue";
import actionString from "@/components/logs/actionString.vue";
import actionArray from "@/components/logs/actionArray.vue";
export default {
  name: "actionChange",
  props:['value', 'param'],
  data() {
    return {
      components: {
        string: actionString,
        object: actionObject,
        array: actionArray,
        number: actionString
      },
      keysRange: [
        'first_name',
        'last_name',
        'login',
        'password',
        'roles',
        'note',
        'permissions',
        'account'
      ]
    }
  },
  methods: {
    getComponentType(item) {
      if(Array.isArray(item)) {
        return 'array';
      }
      if(typeof item === 'string') {
        return 'string';
      }
      if (typeof item === 'boolean') {
        return 'boolean';
      }
      if (typeof item === 'number') {
        return 'number'
      }
      return 'object';
    }
  },
}
</script>

<style scoped>

</style>