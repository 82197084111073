<template>
<div class="">
  {{$t('user.'+param)}}: {{param !== 'expired_at' ? value: moment(value, 'X').format('DD.MM.YYYY')}}
</div>
</template>

<script>
import moment from "moment";
export default {
  name: "actionString",
  props: ['param', 'value'],
  setup() {
    return {
      moment
    }
  }
}
</script>

<style scoped>

</style>