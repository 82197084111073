<template>
  <div class="contentContainer container">
    <div class="card py-3 px-3">
      <div class="block">
        <h4>{{ $t('billing.period_selection') }}</h4>
        <date-range-picker
            style="width: max-content"

            :opens="'right'"
            :ranges="calendarTranslate.ranges[$i18n.locale]"
            v-model="dateRange"
            :locale-data="calendarTranslate.calendar[$i18n.locale]"
        >
          <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end " >
            <div class="d-flex align-items-center">
              <b >{{ data.rangeText }}</b>
            </div>
            <div class="d-grid p-10 d-lg-flex ">
              <b-button @click="data.clickApply" v-if="!data.in_selection" variant="primary" class="acceptButton btn">{{ $t('billing.applyLabel') }}</b-button>
              <button @click="data.clickCancel" class="btn btn-secondary">{{ $t('billing.cancelLabel') }}</button>
            </div>
          </div>
        </date-range-picker>
      </div>



      <div class="block">
        <users-list
            ref="userList"
            :users-list="usersList"
            :users-loading="usersLoading"
            @setUsers="setUsers"
        />
      </div>

      <div class="block" style="margin-bottom: 1rem">
        <events-list
            :events="events"
            @setEvents="setEvents"
        />
      </div>


      <div class="block">
        <b-col sm="5" md="6" class="my-1" v-if="userActivity.total > perPage">
          <b-form-group
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" class="my-1" v-if="userActivity.total > perPage">
          <b-pagination
              v-model="currentPage"
              :total-rows="userActivity.total"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
        <b-table
            head-variant="light"
            :items="activityList"
            :current-page="currentPage"
            :per-page="perPage"
            :fields="fields"
            :empty-text="$t('billing.empty_text')"
            :busy="isLoading"
            responsive
            show-empty
            small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(subject)="row">
            {{row.value}}
          </template>

          <template #cell(created_at)="row">
            {{row.value | moment('DD.MM.YYYY')}}
          </template>
          <template #cell(event)="row">
            {{$t('billing.'+row.value)}}
          </template>

          <template #cell(details)="row">
            <div>{{$t('billing.from')}}:</div>
<!--            <div>{{$t('billing.to')}} {{row.item.subject}}</div>-->
            <div>{{row.item.subject}}</div>

            <div v-if="typeof row.value !=='undefined'">
              <action-change
                  v-if="row.item.event !=='created'"
                  :value="row.value"
              />
            </div>

          </template>

          <template #cell(old)="row">
            <div v-if="typeof row.details ==='undefined'">
              <action-change
                  :value="row.value"
              />
            </div>

          </template>

          <template #cell(newVal)="row">
            <div v-if="typeof row.details ==='undefined'">
              <action-change
                  :value="row.value"
              />
            </div>
            <div v-if="row.item.event ==='created'">
<!--              {{row}}-->
              <action-change
                  :value="row.item.details"
              />
            </div>

          </template>

        </b-table>
      </div>

    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import {mapGetters} from "vuex";
import moment from "moment";
import ActionChange from "@/components/logs/actionСhange";
import UsersList from "@/components/billing/usersList";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import calendarTranslate from "@/configs/calendarTranslate";
import i18n from "@/i18n";
import Multiselect from 'vue-multiselect'
import EventsList from "@/components/billing/eventsList";


export default {
  name: "Logs",
  components: {EventsList, UsersList, ActionChange, DateRangePicker, Multiselect },
  setup() {
    return {
      moment,
      calendarTranslate
    }
  },
  data() {
    return {
      isLoading: false,
      usersLoading: false,
      selectedEvent: '',
      dateRange: {
        startDate: moment(new Date()).subtract(7, 'days').toDate(),
        endDate:   moment(new Date()),
      },
      causersIds: [],
      usersIds: [],
      tenantUsersId: [],
      events: [
        "created",
        "updated",
      ],
      currentPage: 1,
      perPage: 50,
    }
  },
  mounted() {
    this.getUsersList();
  },
  watch:{
    "dateRange.startDate"(){
      this.getUserActivity()
    },
    "dateRange.endDate"(){
      this.getUserActivity()
    },
    usersIds(){
      if (this.usersIds.length){
        this.getUserActivity()
      } else {
        this.$store.dispatch('logs/clearUserActivity')
      }
    },
    tenantUsersId(){
      this.getUserActivity()
    },
    selectedEvent(){
      this.getUserActivity()
    },
  },
  methods:{
    setUsers(val){
      this.usersIds = val
    },
    setEvents(val){
      this.selectedEvent = val;
    },
    getUsersList(){
      this.usersLoading = true
      Promise.all([
        this.$store.dispatch('logs/getAllUserList'),
        this.$store.dispatch('logs/getHiddenUserList')
      ]).finally(() => {
        this.hiddenUserList.forEach(user=> {
          this.causersIds.push(user.id)
        })
        this.usersLoading = false
        this.$refs['userList'].selectUsers()

      })
    },
    getUserActivity(){
      let payload = {
        limit: 500,
        offset: 0,
      }
      let filters = {
        created_from: moment(this.dateRange.startDate).format('X'),
        created_to:  moment(this.dateRange.endDate).format('X'),
      }
      if (typeof this.usersIds.length !== 'undefined' && this.usersIds.length){
        filters.causer_id = this.usersIds
      }
      if (typeof this.selectedEvent.length !== 'undefined' &&  this.selectedEvent.length){
        filters.event = this.selectedEvent
      }
      if (typeof this.causersIds.length !== 'undefined' && this.causersIds.length){
        filters.subject_id = this.causersIds
      }
      if (typeof filters.causer_id !== 'undefined' || typeof filters.subject_id !== 'undefined'){
        payload.filters = filters
        this.isLoading = true;
        this.$store.dispatch('logs/getUserActivity', payload).then(()=>{
          this.isLoading = false;
        })
      }
    },
  },
  computed:{
    ...mapGetters('logs', ["usersList", "hiddenUserList", "userActivity"]),

    activityList(){
      let arr = this.userActivity.data;
      if (typeof arr !== 'undefined'){
        arr.forEach(event=>{
          let details;
          let old;
          let newVal;
          if (typeof event.properties !== 'undefined' && typeof event.properties.old === 'undefined'){
            details = event.properties.attributes
          } else {
            if (typeof event.properties !== 'undefined'){
              old = event.properties.old
              newVal = event.properties.attributes
            }
          }
          event.old = old
          event.newVal = newVal
          event.details = details
        })
        return arr
      }

    },
    fields(){
      return [
        {
          key: 'causer',
          label: this.$t('billing.login'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('billing.date'),
          sortable: true
        },
        {
          key: "event",
          label: this.$t('billing.event'),
          sortable: true
        },
        {
          key: "details",
          label: this.$t('billing.details'),
          sortable: false
        },
        {
          key: "old",
          label: this.$t('billing.previous_value'),
          sortable: false
        },
        {
          key: "newVal",
          label: this.$t('billing.new_value'),
          sortable: false
        },
      ]
    },
  },
  beforeDestroy() {
    this.$store.dispatch('logs/clearUserActivity')
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

@media screen and (min-width: 768px){
  body .daterangepicker.show-ranges {
    min-width: 770px !important;
  }
  .acceptButton{
    margin-right: 8px;
  }
}
@media screen and (max-width: 768px){
  body .daterangepicker.show-ranges {
    min-width: 340px !important;
  }
  .acceptButton{
    margin-bottom: 8px;
  }
}


body .daterangepicker {
  z-index: 10 !important
}
body .border-checkbox-group.border-checkbox-group-primary {
  display: block;
}
@media (min-width: 993px){
  body .border-checkbox-group.border-checkbox-group-primary {
    display: block;
  }
}
.thead-light th {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
tbody td {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
@media screen and (min-width: 768px){
 body .daterangepicker.show-ranges {
    min-width: 770px !important;
  }
}
@media screen and (max-width: 768px){
  body .daterangepicker.show-ranges {
    min-width: 340px !important;
  }
}

/*.multiselect__option--selected:after {*/

/*  background-color: #f3f3f3;*/
/*  background-size: 15px;*/
/*  background-position: center;*/
/*}*/
/*.multiselect__option--selected.multiselect__option--highlight {*/
/*  background-color: #d7d7d7;*/
/*  color: #35495e;*/
/*}*/
/*.multiselect__option--selected.multiselect__option--highlight:after {*/
/*  background-color: #d7d7d7;*/
/*  background-size: 15px;*/
/*  background-position: center;*/
/*}*/
/*.multiselect__option--highlight {*/
/*  background-color: #f3f3f3;*/
/*  color: #35495e;*/
/*}*/
</style>

<style lang="scss">

body .daterangepicker .acceptButton{
  background-color: $primary-color !important;
}

body .daterangepicker .acceptButton:hover{
  background-color: #73b4ff !important;
}
.tables{
  .nav-link{
    color: rgb(64, 153, 255);
  }
}
.daterangepicker td.active{
  background-color: #4099ff;
}
.daterangepicker td.active:hover{
  background-color: #73b4ff;
}

.daterangepicker .ranges .active {
  background-color: $primary-color !important;
}
.daterangepicker .ranges .active:hover {
  background-color: #73b4ff !important;
}
</style>