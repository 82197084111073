<template>
<div class="">
  {{$t('user.'+param)}}: {{value.map(item => $t('user.'+item)).join(', ')}}
<!--  {{$t('user.'+param)}}: {{$t('user.'+value) value.join(', ')}}-->
</div>
</template>

<script>
export default {
  name: "actionArray",
  props: ['param', 'value'],

}
</script>

<style scoped>

</style>