<template>
 <div>
   {{$t('user.'+param)}}: {{$t('billing.'+value) }}
 </div>
</template>

<script>
export default {
  name: "actionBoolean",
  props: ['param', 'value']
}
</script>

<style scoped>

</style>